import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"

const AboutPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>About Einar Þór Gústafsson</title>
      
      </Helmet>
      <div className="two-grids -about">
        <div className="post-thumbnail" style={{backgroundImage: `url('/assets/einar-sydney.jpg')`, marginBottom: 0}}>
          <h1 className="post-title">About me</h1>
        </div>
        <div>  
            <p>I am a product manager with experience working in Health, Finance, Travel and eCommerce. My background is in interaction and information design as well as web development. Still enjoy playing around with new technology and designing.</p>
            
          <p>Over the past 20 years I have helped orginizations around the world drive innovation, design and development. Including some of the worlds largest banks as well as travel companies.</p>
          
       
          <h2>Currently</h2>
          <p>Product Manager at <a href="https://gangverk.com">Gangverk</a> a digital product agency where I lead product for an app provided by <a href="https://thekey.com">TheKey</a> for the clients. TheKey is one of the largest home care providers in the US and Cana.</p>

          <h2>Previously</h2>

          <p>VP of product and engineering at <a href="https://tixticketing.com">Tix Ticketing</a>, a fast growing ticketing solution focusing on culture houses, theatres, operas and such. Currently live in 8 countries mostly in Europe but growing quickly around the world.</p>

          <p>Studio Manager at <a href="https://aranja.com">Aranja</a> and Business Development at <a href="https://hopp.bike">Hopp Mobility</a>. Aranja is a development studio pushing the boundaries of web technologies to implement beautiful user experiences for clients such as Google, Facebook, The Blue Lagoon and their own startup Hopp Mobility. As studio manager I was responsible for operations but spent significant time with the Hopp Mobility team on fundraising, go to market strategies and growth.</p>

          <p>I founded <a href="https://getlocl.com">Getlocal</a> in 2016 - a SaaS company in traveltech focusing on improving sales for travel companies around the world with a powerful e-commerce solution that increases sales and improves marketing. We currently have customers in over 20 countries and a franchise in <a href="https://getlocal.co.za">South Africa</a>.</p>

          <p>Before pivoting into providing the SaaS e-commerce solution we started as an <a href="https://getlocal.is">Icelandic <abbr title="Online Travel Agency">OTA</abbr></a> and <a href="https://getlocal.travel">local travel advice</a> platform. </p>

          <p>VP of product at <a href="https://bokun.io">Bokun</a>, an exciting startup in the travel industry that has since been aquired by Tripadvisor and is currently the worlds leading inventory management and booking solution. Shortly after joining I decided to follow my true passion and started Getlocal.</p>

          <p>I joined <a href="https://meniga.com">Meniga</a> as VP of Product Management in 2010 and spent amazing 6 years there helping grow the company from 5 employees to over 100. Meniga is one of the world’s leading FinTech solutions and is currently used by over 70 million people around the world. Customers include Commerzbank, Santander, Unicredit and Nordea.</p>
         
          <p>I spent seven years working for <a href="https://islandsbanki.is">Íslandsbanki/Glitnir Bank</a> in a few roles including Executive Director of Global Internet Strategy Development Manager of Internet banking &amp; Web, Webmaster, development team lead and web designer.</p>

          
        
        <h2>Community</h2>
          
          <p>I co-founded <a href="https://svef.is">SVEF</a>, the Icelandic Web Industry Association, in 2005 and was chairman from 2009 to 2013. SVEF hosts the Icelandic Web Awards and organizes the IceWeb Conference which is one of the oldest web design conferences in the world.</p>
          </div>
          
          
          
      </div>
      
    </Layout>
  )
}
export default AboutPage
